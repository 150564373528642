import { defineStore } from 'pinia';
import { getFirestore, doc, getDoc, collection, query, where, limit, orderBy, onSnapshot, updateDoc, getDocs } from 'firebase/firestore';

import { loggedInUserStore } from '@/stores/userdata';
import type { CompanyVessels } from '@/types/CompanyVessels.types';
import type { Vessel } from '@/types/Vessel.types';
import type { Fleet, FleetVesselsMap } from '@/types/Fleet.types';
import type { PageData } from '@/types/PageData.types';
import type { Plan } from '@/types/Plan.types';
import type { Crew } from '@/types/Crew.types';
import type { DashboardSettings } from '@/types/DashboardSettings.types';

export const currentDataStore: {} = defineStore({
    id: 'currentData', // must be unique for each store file
    state: () => ({
        loadingStatus: false,
        userID: '',
        isNumzaan: false,
        currentFleetID: {},
        currentFleet: {} as Fleet,
        currentFleetLoaded: false,
        currentFleetUserXCD: false,

        currentVesselID: '',
        currentVesselName: '',
        currentVessel: {} as Vessel,
        currentVesselBasicData: {} as Vessel,
        currentVesselLoaded: false,
        currentBuilderLoaded: true,
        currentVesselIsFleet: false,
        currentVesselPlans: false,
        currentAuditVessels: [] as Vessel[],
        currentVesselStats: {},

        currentFleetVessels: [] as Vessel[],
        currentFleetVesselsLoaded: false,
        currentFleetVesselsMap: {} as FleetVesselsMap,
        currentFleetVesselsData: [] as Vessel[],
        currentFleetItemTags: [],
        currentFleetAssetTags: [],
        currentFleetTeamTags: [],

        currentTeamMember: {},

        currentAssetGroups: [],
        currentAdminGroupIDs: [],
        currentAdminPermissions: {},

        latestAssetAudit: {},
        systemNotifications: [],
        companyNotices: [],

        colorOn: false,

        currentPageData: {} as PageData,
        currentVesselTab: '1',
        currentRequiredPlans: [] as Plan,

        squareCustomerID: '',
        myDashboardSettings: {} as DashboardSettings,
        baseDashboardSettings: {
            vessels: [true, true, true, true, true, true, true, true, true, true, true],
            people: [true, true, true, true, true, true],
            procedures: [true, true, true, true, true, true],
            logs: [true, true, true, true, true, true, true, true, true, true, true, true, true],
            ops: [true, true, true],
            history: [true, true, true, true, true, true, true, true, true, true, true, true, true]
        } as DashboardSettings,
        adminRestrictedUsers: [] as string[],
        currentFleetAdmins: [],
        // currentFleetRestrictedAdmins: [],
        currentFleetPrimaryAdmins: [],
        currentFleetStats: {},
        currentFleetAdminData: [],
        currentFleetUserIsRestricted: false,
        currentAdminStatus: 'none',
        menuExpanded: true,
        snapshotSections: [] as string[],
        snapshotSectionsChanged: false,
        allVesselCrewIDs: [] as string[],
        allVesselCrews: [] as Crew[],
        filterTags: [] as string[],
        filterCategories: [] as string[],
        microHelperTags: [] as string[],
        slidemenuOpen: false,
        showMobileNav: false,

        currentFleetDashWidgets: [],
        currentFleetDashShortcuts: [],
        shortcutsFleetContent: [],
        shortcutsFaveTeam: [],
        shortcutsFaveAssets: [],
        currentAssetCalendarLoaded: false,
        currentAssetCalendarEvents: [],
        currentAssetCustomLogsLoaded: false,
        currentAssetCustomLogs: [],

        currentFleetTeamMembers: [],
        currentUserTeamTags: []
    }),

    persist: true,

    getters: {
        getLoadingStatus: (state) => state.loadingStatus,
        getCurrentFleetID: (state) => state.currentFleetID,
        getCurrentFleet: (state) => state.currentFleet,
        getCurrentFleetData: (state) => state.currentFleet,
        getCurrentFleetLoaded: (state) => state.currentFleetLoaded,

        getCurrentFleetUserXCD: (state) => state.currentFleetUserXCD,

        getCurrentVesselID: (state) => state.currentVesselID,
        getCurrentVesselName: (state) => state.currentVesselName,
        getCurrentVessel: (state) => state.currentVessel,
        getCurrentVesselBasicData: (state) => state.currentVesselBasicData,
        getCurrentVesselLoaded: (state) => state.currentVesselLoaded,
        getCurrentBuilderLoaded: (state) => state.currentBuilderLoaded,
        getCurrentVesselIsFleet: (state) => state.currentVesselIsFleet,
        getCurrentVesselPlans: (state) => state.currentVesselPlans,
        getCurrentAuditVessels: (state) => state.currentAuditVessels,
        getCurrentVesselStats: (state) => state.currentVesselStats,
        getCurrentFleetAssetTags: (state) => state.currentFleetAssetTags,
        getCurrentFleetTeamTags: (state) => state.currentFleetTeamTags,

        getCurrentFleetVessels: (state) => state.currentFleetVessels,
        getCurrentFleetVesselsLoaded: (state) => state.currentFleetVesselsLoaded,
        getCurrentFleetVesselsMap: (state) => state.currentFleetVesselsMap,
        getCurrentFleetVesselsData: (state) => state.currentFleetVesselsData,
        getCurrentFleetItemTags: (state) => state.currentFleetItemTags,

        getCurrentTeamMember: (state) => state.currentTeamMember,

        getColorOn: (state) => state.colorOn,
        getCurrentPageData: (state) => state.currentPageData,
        getCurrentVesselTab: (state) => state.currentVesselTab,
        getCurrentRequiredPlans: (state) => state.currentRequiredPlans,

        getSquareCustomerID: (state) => state.squareCustomerID,
        getLatestAssetAudit: (state) => state.latestAssetAudit,
        getSystemNotifications: (state) => state.systemNotifications,
        getCompanyNotices: (state) => state.companyNotices,

        getMyDashboardSettings: (state) => state.myDashboardSettings,
        getAdminRestrictedUsers: (state) => state.adminRestrictedUsers,
        // getCurrentFleetUserIsRestricted: (state) => state.currentFleetUserIsRestricted,
        getCurrentFleetUserIsRestricted: (state) => false, // TEMP FOR DEV
        getCurrentAdminStatus: (state) => state.currentAdminStatus,
        getCurrentFleetAdmins: (state) => state.currentFleetAdmins,
        getCurrentFleetStats: (state) => state.currentFleetStats,
        getCurrentFleetAdminData: (state) => state.currentFleetAdminData,

        getMenuExpanded: (state) => state.menuExpanded,
        getSnapshotSections: (state) => state.snapshotSections,
        getSnapshotLayoutChanged: (state) => state.snapshotSectionsChanged,

        getAllVesselCrewIDs: (state) => state.allVesselCrewIDs,
        getAllVesselCrews: (state) => state.allVesselCrews,
        getFilterCategories: (state) => state.filterCategories,
        getMicroHelperTags: (state) => state.microHelperTags,
        getFilterTags: (state) => state.filterTags,
        getSlidemenuOpen: (state) => state.slidemenuOpen,
        getShowMobileNav: (state) => state.showMobileNav,

        getCurrentAssetGroups: (state) => state.currentAssetGroups,
        getCurrentAdminPermissions: (state) => state.currentAdminPermissions,
        getCurrentAdminGroupIDs: (state) => state.currentAdminGroupIDs,

        getCurrentFleetDashWidgets: (state) => state.currentFleetDashWidgets,
        getCurrentFleetDashShortcuts: (state) => state.currentFleetDashShortcuts,
        getShortcutsFleetContent: (state) => state.shortcutsFleetContent,
        getShortcutsFaveTeam: (state) => state.shortcutsFaveTeam,
        getShortcutsFaveAssets: (state) => state.shortcutsFaveAssets,

        getCurrentAssetCalendarLoaded: (state) => state.currentAssetCalendarLoaded,
        getCurrentAssetCalendarEvents: (state) => state.currentAssetCalendarEvents,

        getCurrentAssetCustomLogsLoaded: (state) => state.currentAssetCustomLogsLoaded,
        getCurrentAssetCustomLogs: (state) => state.currentAssetCustomLogs,

        getCurrentFleetTeamMembers: (state) => state.currentFleetTeamMembers,
        getCurrentUserTeamTags: (state) => state.currentUserTeamTags
    },
    actions: {
        setLoadingStatus(value: boolean) {
            this.loadingStatus = value;
        },
        setCurrentBuilderLoaded(value: boolean) {
            this.currentBuilderLoaded = value;
        },
        setSlidemenu(value: boolean) {
            // console.log(value);
            this.slidemenuOpen = value;
        },
        setMobileNav(value: boolean) {
            this.showMobileNav = value;
        },

        setCurrentAssetCalendarLoaded(value: boolean) {
            this.currentAssetCalendarLoaded = value;
        },

        setCurrentAssetCalendarEvents(events: Array) {
            this.currentAssetCalendarEvents = events;
        },

        setCurrentAssetCustomLogsLoaded(value: boolean) {
            this.currentAssetCustomLogsLoaded = value;
        },

        setCurrentAssetCustomLogs(logs: Array) {
            this.currentAssetCustomLogs = logs;
        },

        clearCurrentVesselName() {
            this.currentVesselName = '';
            this.currentVesselID = '';
        },

        setCurrentFleet(fleetID: string) {
            // console.log('setting current fleet', fleetID);
            this.currentAuditVessels = [];
            this.currentFleetLoaded = false;
            this.currentFleetID = fleetID;
            this.currentVesselID = '';
            this.userID = loggedInUserStore().getUserID;
            this.isNumzaan = loggedInUserStore().getIsNumzaan;
            this.setCurrentFleetDashboard(fleetID);
            onSnapshot(doc(getFirestore(), 'companies', fleetID), (doc) => {
                if (doc.exists()) {
                    const data = doc.data() ?? {};
                    data.id = fleetID;
                    let adminUsers = data.adminUsers ?? [];
                    this.currentFleetAdmins = adminUsers;
                    this.currentFleetUserXCD = adminUsers.includes(this.userID) || this.isNumzaan;
                    let restrictedAdminUsers = data.adminRestrictedUsers ?? [];
                    let primaryAdminIDs = data.primaryAdminIDs ?? [];
                    this.adminRestrictedUsers = data.adminRestrictedUsers ?? [];
                    this.currentAdminStatus = primaryAdminIDs.includes(this.userID)
                        ? 'primary'
                        : adminUsers.includes(this.userID)
                        ? 'full'
                        : restrictedAdminUsers.includes(this.userID)
                        ? 'restricted'
                        : this.isNumzaan
                        ? 'offshore'
                        : 'none';
                    this.currentFleetUserIsRestricted = this.adminRestrictedUsers.includes(this.userID);
                    data.assetReloadBin = doc.data().assetReloadBin ?? [];
                    this.currentFleet = data;
                    this.currentFleetStats = data.fleetStats ?? {
                        totalAssets: 0,
                        activeAssets: 0,
                        offlineAssets: 0,
                        totalAssetGroups: 0,
                        totalUngroupedAssets: 0,
                        totalExpiredAssets: 0,
                        assetsExpiringSoon: 0,
                        activeSmsTokens: 0
                    };
                    // console.log('fleetStats', data.fleetStats, this.currentFleetStats);
                    this.setCurrentFleetAdmins(fleetID, adminUsers);
                }
            });
            this.loadCurrentFleetTeam(fleetID);
            this.loadCurrentFleetVessels(fleetID);
            this.loadSystemNotifications(fleetID);
            this.loadCompanyNotices(fleetID);
            setTimeout(() => {
                this.currentFleetLoaded = true;
            }, 500);
        },

        async loadCurrentFleetTeam(fleetID: string) {
            const docRef = doc(getFirestore(), 'companies/' + fleetID + '/teamMembers', this.userID);
            const docSnap = await getDoc(docRef);
            let adminIsFull = false;
            if (docSnap.exists()) {
                let isAdmin = this.currentFleetAdmins.includes(this.userID);
                let isRestrictedAdmin = this.adminRestrictedUsers.includes(this.userID);
                let roles = docSnap.data().roles ?? [];
                let restrictedAdminTag = isRestrictedAdmin ? ['Restricted Admin'] : [];
                let fullAdminTag = isAdmin && !isRestrictedAdmin ? ['Full Admin'] : [];
                adminIsFull = isAdmin && !isRestrictedAdmin;
                this.currentUserTeamTags = [...roles, ...fullAdminTag, ...restrictedAdminTag];
                // this.currentUserTeamTags = [...roles, ...fullAdminTag];
            }
            const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));
            await sleep(1000);

            const q = query(collection(getFirestore(), 'companies/' + fleetID + '/teamMembers'), orderBy('crewName', 'asc'));
            const qualSnapshot = onSnapshot(q, (querySnapshot) => {
                this.currentFleetTeamMembers = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data();
                    data.id = doc.id;
                    let isAdmin = this.currentFleetAdmins.includes(doc.id);
                    data.isAdmin = isAdmin;
                    let isRestrictedAdmin = this.adminRestrictedUsers.includes(doc.id);
                    data.isRestrictedAdmin = isRestrictedAdmin;
                    data.hasAlerts = false;
                    let isPrimaryAdmin = this.currentFleetPrimaryAdmins.includes(doc.id);
                    let roles = doc.data().roles ?? [];
                    let restrictedAdminTag = isRestrictedAdmin ? ['Restricted Admin'] : [];
                    let fullAdminTag = isAdmin && !isRestrictedAdmin ? ['Full Admin'] : [];
                    // data.roles = [...roles, ...fullAdminTag, ...restrictedAdminTag];
                    data.roles = [...roles];
                    data.assetTags = [...roles, ...fullAdminTag, ...restrictedAdminTag];

                    // this.currentFleetTeamMembers.push(data);
                    if (adminIsFull || this.currentUserTeamTags.some((t) => data.roles.includes(t))) this.currentFleetTeamMembers.push(data);
                });
            });
        },

        async loadCompanyNotices(fleetID: string) {
            const q = query(
                collection(getFirestore(), 'companies/' + fleetID + '/companyNotices'),
                where('archived', '==', false),
                orderBy('timestamp', 'desc')
            );
            onSnapshot(q, (querySnapshot) => {
                this.companyNotices = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.timestamp = new Date(doc.data().timestamp.toDate());
                    this.companyNotices.push(data);
                });
            });
        },

        async loadSystemNotifications(fleetID: string) {
            // systemNotifications
            const q = query(
                collection(getFirestore(), 'companies/' + fleetID + '/systemNotifications'),
                where('archived', '==', false),
                orderBy('timestamp', 'desc')
            );
            onSnapshot(q, (querySnapshot) => {
                this.systemNotifications = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.timestamp = new Date(doc.data().timestamp.toDate());
                    this.systemNotifications.push(data);
                });
            });
        },

        // LOADSYSTEMNOTIFICATIONS: (state, payload) => {
        //     // console.log(payload.fleetID);
        //     db.collection('companies')
        //         .doc(payload.fleetID)
        //         .collection('systemNotifications')
        //         .where('archived', '==', false)
        //         .orderBy('timestamp', 'desc')
        //         .onSnapshot((querySnapshot) => {
        //             state.systemNotifications = [];
        //             querySnapshot.forEach(async (doc) => {
        //                 let data = doc.data();
        //                 data.id = doc.id;
        //                 state.systemNotifications.push(data);
        //             });
        //         });
        // },

        setCurrentFleetDashboard(fleetID: string) {
            if (this.userID && this.userID != '') {
                const unsub = onSnapshot(doc(getFirestore(), 'userdata', this.userID, 'myFleets', fleetID), (doc) => {
                    if (doc.exists()) {
                        this.currentFleetDashWidgets = doc.data().savedDashWidgets ?? [];
                        this.currentFleetDashShortcuts = doc.data().savedDashShortcuts ?? [];
                        this.shortcutsFleetContent = doc.data().shortcutsFleetContent ?? [];
                        this.shortcutsFaveTeam = doc.data().shortcutsFaveTeam ?? [];
                        this.shortcutsFaveAssets = doc.data().shortcutsFaveAssets ?? [];
                    }
                });
            }
        },

        setCurrentFleetAdmins(fleetID: string, adminUsers) {
            // console.log('XX', fleetID, adminUsers);
            this.currentFleetAdminData = [];
            let adminIDs = [];
            let self = this;
            if (this.adminRestrictedUsers.length > 0) {
                Promise.all(
                    self.adminRestrictedUsers.map(async (id) => {
                        const docRef = await getDoc(doc(getFirestore(), 'userdata', id));
                        if (!adminIDs.includes(id)) {
                            // console.log('adding', docRef.data().username);
                            adminIDs.push(id);
                            self.currentFleetAdminData.push({
                                username: docRef.data().username,
                                userid: id,
                                avatarUrl: docRef.data().avatarUrl ?? '',
                                restricted: true
                            });
                        }
                    })
                );
            }
            if (this.currentFleetAdmins) {
                Promise.all(
                    this.currentFleetAdmins.map(async (id) => {
                        const docRefTwo = await getDoc(doc(getFirestore(), 'userdata', id));
                        if (!adminIDs.includes(id)) {
                            // console.log('adding', docRefTwo.data().username);
                            adminIDs.push(id);
                            self.currentFleetAdminData.push({
                                username: docRefTwo.data().username,
                                userid: id,
                                avatarUrl: docRefTwo.data().avatarUrl ?? '',
                                restricted: false
                            });
                        }
                    })
                );
            }
            // console.log('admins set');
        },

        async loadCurrentFleetVessels(fleetID: string) {
            this.currentFleetVesselsLoaded = false;
            const q = query(collection(getFirestore(), 'companies/' + fleetID + '/companyVessels'), orderBy('vesselName', 'asc'));
            onSnapshot(q, (querySnapshot) => {
                this.currentFleetVessels = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data() as CompanyVessels;
                    data.id = doc.id;
                    data.vesselID = doc.id;
                    this.currentFleetVessels.push(data);
                });
            });
            setTimeout(() => {
                this.currentFleetVesselsLoaded = true;
                // this.loadCurrentFleetVesselsMap(); // ! removed as may not need to load every vessel's data into the state for the fleet dashboard, only audit vessel list!
                this.setCurrentAuditVessels(); // was in loadCurrentFleetVesselsMap()
            }, 500);
            // setTimeout(() => {
            //     this.setCurrentAuditVessels();
            // }, 500);
        },

        async loadAllAssetData(fleetID: string) {
            // console.log('OK', this.currentAdminGroupIDs, this.currentAssetGroups);
            let self = this;
            let temp = this.currentAssetGroups
                .filter((m) => this.currentAdminGroupIDs.includes(m.id))
                .flatMap((g: any) => g.members)
                .filter((v, i, a) => a.indexOf(v) == i);
            // console.log('temp', temp);
            this.currentFleetVesselsData = [];
            let t = [];
            let fleetStats = {
                totalAssets: 0,
                activeAssets: 0,
                offlineAssets: 0,
                totalAssetGroups: 0,
                totalUngroupedAssets: 0,
                totalExpiredAssets: 0,
                assetsExpiringSoon: 0,
                activeSmsTokens: 0
            };

            const now = new Date();
            const cutOff = new Date(new Date().setDate(new Date().getDate() + 30));
            const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };

            Promise.all(
                temp.map(async (assetID) => {
                    const unsub = onSnapshot(doc(getFirestore(), 'vessels', assetID), (doc) => {
                        if (doc.exists()) {
                            const data = doc.data();
                            // console.log('asset', data.vesselName);
                            data.vesselID = assetID;
                            // can force set any other required fields here:
                            // set up stats....
                            data.avatarUrl = doc.data().avatarUrl ?? '';
                            // data.nextChargeDate = doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : new Date();

                            let xDate = doc.data().nextChargeDate.toDate();
                            data.xDate = xDate;
                            data.isOverdue = xDate < now;
                            data.isDue = xDate < cutOff && xDate > now;
                            data.dueDate = new Date(xDate).toLocaleDateString('en-US', dateOptions);

                            self.currentFleetVesselsMap[assetID] = data;

                            // set up fleet stats
                            fleetStats.totalAssets++;
                            if (data.isActive) fleetStats.activeAssets++;
                            if (data.offlineStatusData?.vesselStatus == 'offline') fleetStats.offlineAssets++;
                            if (data.isExpired) fleetStats.totalExpiredAssets++;
                            if (data.nextChargeDate) {
                                let nextChargeDate = new Date(data.nextChargeDate);
                                if (nextChargeDate < cutOff) {
                                    fleetStats.assetsExpiringSoon++;
                                }
                            }

                            //
                            if (!t.includes(assetID)) {
                                t.push(assetID);
                                self.currentFleetVesselsData.push(data);
                            }
                        }
                    });
                })
            );

            const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));
            await sleep(3000);

            // console.log('fleetStats', fleetStats);
            this.currentFleetStats = fleetStats;
            // console.log(
            //     'self.currentFleetVesselsData',
            //     self.currentFleetVesselsData.map((m) => m.vesselName)
            // );

            const updateRef = doc(getFirestore(), 'companies', fleetID);
            await updateDoc(updateRef, {
                assetReloadBin: [],
                fleetStats: fleetStats
            });
        },

        async emptyTheAssetBin(fleetID: string) {
            let self = this;
            // console.log('Starting to empty the asset bin...');
            const docSnap = await getDoc(doc(getFirestore(), 'companies', fleetID));
            if (docSnap.exists()) {
                const assetReloadBin = docSnap.data().assetReloadBin ?? [];
                if (assetReloadBin.length > 0) {
                    // console.log('assetReloadBin has items - reloading and emptying..');
                    let temp = [...assetReloadBin];
                    const updateRef = doc(getFirestore(), 'companies', fleetID);
                    await updateDoc(updateRef, { assetReloadBin: [] });
                    await Promise.all(
                        temp.map(async (m) => {
                            const unsub = onSnapshot(doc(getFirestore(), 'vessels', m), (doc) => {
                                if (doc.exists()) {
                                    const data = doc.data();
                                    data.vesselID = m;
                                    data.avatarUrl = doc.data().avatarUrl ?? '';
                                    // can force set any other required fields here:
                                    data.isAudited = doc.data().isAudited ?? false;
                                    data.isPrivate = doc.data().isPrivate ?? false;
                                    data.nextChargeDate = doc.data().nextChargeDate;
                                    data.isExpired = doc.data().isExpired ?? false;
                                    data.isActive = doc.data().isActive ?? false;
                                    data.savedAssetTags = doc.data().savedAssetTags ?? [];
                                    self.currentFleetVesselsMap[doc.id] = data;
                                }
                            });
                        })
                    );
                } else {
                    // console.log('assetReloadBin is already empty.');
                }
            }
        },

        async forceReloadFleetAssets(fleetID: string) {
            let self = this;
            // console.log('R:', this.currentFleetVessels);
            this.currentFleetVesselsMap = {};
            const updateRef = doc(getFirestore(), 'companies', fleetID);
            await updateDoc(updateRef, { assetReloadBin: [] });
            const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));
            await sleep(200);
            await Promise.all(
                this.currentFleetVessels.map(async (m) => {
                    // console.log('m', m.vesselName, m.vesselID);
                    const unsub = onSnapshot(doc(getFirestore(), 'vessels', m.vesselID), (doc) => {
                        if (doc.exists()) {
                            // console.log('m2', m.vesselName, m.vesselID);
                            const data = doc.data();
                            data.vesselID = m.vesselID;
                            data.avatarUrl = doc.data().avatarUrl ?? '';
                            // can force set any other required fields here:
                            data.isAudited = doc.data().isAudited ?? false;
                            data.isPrivate = doc.data().isPrivate ?? false;
                            data.nextChargeDate = doc.data().nextChargeDate;
                            data.isExpired = doc.data().isExpired ?? false;
                            data.isActive = doc.data().isActive ?? false;
                            data.savedAssetTags = doc.data().savedAssetTags ?? [];
                            self.currentFleetVesselsMap[doc.id] = data;
                        }
                    });
                })
            );
        },

        async loadSingleAssetMap(assetID: string) {
            let self = this;
            const unsub = onSnapshot(doc(getFirestore(), 'vessels', assetID), (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    // console.log('reset', data);
                    data.vesselID = assetID;
                    data.avatarUrl = doc.data().avatarUrl ?? '';
                    // can force set any other required fields here:
                    data.isAudited = doc.data().isAudited ?? false;
                    data.isPrivate = doc.data().isPrivate ?? false;
                    data.nextChargeDate = doc.data().nextChargeDate;
                    data.isExpired = doc.data().isExpired ?? false;
                    data.isActive = doc.data().isActive ?? false;
                    data.savedAssetTags = doc.data().savedAssetTags ?? [];
                    self.currentFleetVesselsMap[doc.id] = data;
                }
            });
        },

        // might need this later, otherwise it loads in the function above if navigating from the users' home page to a fleet.
        // async loadCurrentFleetVesselsMap() {
        //     let self = this;
        //     await Promise.all(
        //         self.currentFleetVessels.map(async (vessel) => {
        //             const vesselID = vessel.vesselID ?? '';
        //             const docSnap = await getDoc(doc(getFirestore(), 'vessels', vesselID));
        //             if (docSnap.exists()) {
        //                 const data = docSnap.data();
        //                 data.vesselID = vessel.vesselID;
        //                 data.avatarUrl = docSnap.data().avatarUrl ?? '';
        //                 // return data; // this was the issue - not assigning the vessel data to currentFleetVesselsMap with the key == the vessel's ID
        //                 self.currentFleetVesselsMap[docSnap.id] = data;
        //             }
        //         })
        //     );
        //     setTimeout(() => {
        //         this.setCurrentAuditVessels();
        //     }, 500);
        // },

        async SETCURRENTASSETGROUPS(fleetID: string) {
            let self = this;
            const q = query(collection(getFirestore(), 'companies/' + fleetID + '/assetGroups'), orderBy('index', 'asc'));
            onSnapshot(q, (querySnapshot) => {
                self.currentAssetGroups = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.showRemove = false;
                    data.showRemoveMembers = false;
                    data.showAdmins = false;
                    data.loadingAdmins = false;
                    data.admins = [];
                    self.currentAssetGroups.push(data);
                });
            });
        },

        async SETCURRENTADMINPERMISSIONS(fleetID: string) {
            const docSnap = await getDoc(doc(getFirestore(), 'companies/' + fleetID + '/adminPermissions', this.userID));
            if (docSnap.exists()) {
                this.currentAdminPermissions = docSnap.data();
                // console.log('WTF', docSnap.data());
                if (docSnap.data().adminAssets) this.currentAdminGroupIDs = docSnap.data().adminAssets;
            }
        },

        // SETCURRENTADMINGROUPVESSELS: (state, payload) => {
        //     // console.log('XX', payload.fleetID);
        //     state.currentAdminGroupVessels = [];
        //     db.collection('companies')
        //         .doc(payload.fleetID)
        //         .collection('adminPermissions')
        //         .doc(state.user.uid)
        //         .get()
        //         .then((doc) => {
        //             if (doc.exists) {
        //                 let adminAssets = doc.data().adminAssets ? doc.data().adminAssets : [];
        //                 adminAssets.forEach((asset) => {
        //                     db.collection('companies')
        //                         .doc(payload.fleetID)
        //                         .collection('assetGroups')
        //                         .doc(asset)
        //                         .get()
        //                         .then((docRef) => {
        //                             if (docRef.exists) {
        //                                 let members = docRef.data().members ? docRef.data().members : [];
        //                                 state.currentAdminGroupVessels = [...state.currentAdminGroupVessels, ...members];
        //                             }
        //                         });
        //                 });
        //             }
        //         });
        // },

        setFleetItemTags() {
            const collectionRef = collection(getFirestore(), 'companies/' + this.currentFleetID + '/fleetItemTags');
            const q = query(collectionRef, orderBy('index'));
            onSnapshot(q, (querySnapshot) => {
                this.currentFleetItemTags = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        const data = doc.data();
                        data.id = doc.id;
                        data.checked = false;
                        this.currentFleetItemTags.push(data);
                    }
                });
            });
        },

        setFleetTeamTags() {
            const collectionRef = collection(getFirestore(), 'companies/' + this.currentFleetID + '/fleetTeamTags');
            const q = query(collectionRef, orderBy('index'));
            onSnapshot(q, (querySnapshot) => {
                this.currentFleetTeamTags = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        const data = doc.data();
                        data.id = doc.id;
                        data.checked = false;
                        this.currentFleetTeamTags.push(data);
                    }
                });
            });
        },

        setFleetAssetTags() {
            const collectionRef = collection(getFirestore(), 'companies/' + this.currentFleetID + '/fleetAssetTags');
            const q = query(collectionRef, orderBy('index'));
            onSnapshot(q, (querySnapshot) => {
                this.currentFleetAssetTags = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        const data = doc.data();
                        data.id = doc.id;
                        data.checked = false;
                        this.currentFleetAssetTags.push(data);
                    }
                });
            });
        },

        setCurrentAuditVessels() {
            let self = this;
            let auditVesselIDs = this.currentFleet.auditVesselIDs ?? [];
            if (this.currentFleetVessels.length > 0) {
                this.currentAuditVessels = this.currentFleetVessels.filter((n) => auditVesselIDs.includes(n.vesselID ?? ''));
            }
        },

        setCurrentVessel(vesselID: string) {
            this.currentVesselLoaded = false;
            this.currentBuilderLoaded = false;
            this.currentVesselID = vesselID;

            const now = new Date();
            const cutOff = new Date(new Date().setDate(new Date().getDate() + 30));
            const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
            // get current vessel snapshot and set currentVessel to its snapshot

            onSnapshot(doc(getFirestore(), 'vessels', vesselID), (doc) => {
                if (doc.exists()) {
                    const data = doc.data();

                    let xDate = data.nextChargeDate.toDate();
                    data.xDate = xDate;
                    data.isOverdue = xDate < now;
                    data.isDue = xDate < cutOff && xDate > now;
                    data.dueDate = new Date(xDate).toLocaleDateString('en-US', dateOptions);

                    console.log('loaded vessel', data.vesselName);
                    if (data) {
                        this.currentVessel = data as Vessel;
                        this.currentVesselName = data.vesselName ?? '';
                        this.currentVesselBasicData = {
                            vesselID: doc.id,
                            vesselName: data.vesselName,
                            avatarUrl: data.avatarUrl ?? ''
                        };
                        this.currentVessel.savedAppInfoSections = data.savedAppInfoSections ?? [];
                        this.currentVessel.vesselIndustries = data.vesselIndustries ?? [];
                        this.currentVessel.customFields = data.customFields ?? [];
                        this.currentVessel.assetType = data.assetType ?? 'vessel';
                        this.currentVessel.assetFilterTags = data.assetFilterTags ?? [];
                        this.currentVesselIsFleet = data.isCompanyVessel ?? false;
                        this.currentVesselPlans = data.aaaPlans ?? [];
                        this.squareCustomerID = data.squareCustomerID ?? '';
                        //
                        this.currentVesselStats = {
                            aaaPlans: data.aaaPlans ?? [],
                            nextChargeDate: data.nextChargeDate ?? new Date(),
                            nextVesselCharge: data.nextVesselCharge ?? 249,
                            isAudited: data.auditVessel ?? false,
                            autoRenew: data.autoRenew ?? false,
                            isActive: data.isActive ?? false,
                            isCompanyVessel: data.isCompanyVessel ?? false,
                            isDemo: data.isDemo ?? false,
                            isFreebie: data.isFreebie ?? false,
                            isExpired: data.isExpired ?? false,
                            offlineStatus: data.offlineStatusData ? data.offlineStatusData.vesselStatus ?? 'live' : 'live',
                            isPrivate: data.isPrivate ?? false,
                            takesPassengers: data.takesPassengers ?? false,
                            savedAssetTags: data.savedAssetTags ?? []
                        };
                    }
                    setTimeout(() => {
                        this.currentVesselLoaded = true;
                        this.currentBuilderLoaded = true;
                    }, 500);
                } else {
                    setTimeout(() => {
                        this.currentVesselLoaded = false;
                    }, 500);
                }
                this.loadMyDashboardSettings();
                this.loadFilterTags();
                this.setLatestAssetAudit(vesselID);
            });
        },

        async setLatestAssetAudit(vesselID: string) {
            const q = query(collection(getFirestore(), 'vessels/' + vesselID + '/vesselAudits'), limit(1), orderBy('timestamp', 'desc'));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs.length > 0) {
                this.latestAssetAudit = querySnapshot.docs[0].data();
            } else {
                this.latestAssetAudit = {};
            }
        },

        async setCurrentTeamMember(crewName: string, crewID: string) {
            // console.log('crewName', crewName);
            // const q = query(collection(getFirestore(), 'vessels/' + vesselID + '/vesselAudits'), limit(1), orderBy('timestamp', 'desc'));
            // const querySnapshot = await getDocs(q);
            // if (querySnapshot.docs.length > 0) {
            //     this.latestAssetAudit = querySnapshot.docs[0].data();
            // } else {
            //     this.latestAssetAudit = {};
            // }
            this.currentTeamMember = {
                crewName: crewName,
                crewID: crewID
            };
            // console.log('X:', this.currentTeamMember);
        },

        async setCurrentVesselCrews() {
            setTimeout(async () => {
                let self = this;
                let levelOne = this.currentVessel.listOfCrewIDs ?? [];
                let levelTwo = this.currentVessel.listOfLevelTwoIDs ?? [];
                let levelThree = this.currentVessel.listOfMasterIDs ?? [];
                // console.log(levelOne, levelTwo, levelThree);
                await Promise.all(
                    levelThree.map(async (id) => {
                        if (!self.allVesselCrewIDs.includes(id)) {
                            const docSnap = await getDoc(doc(getFirestore(), 'userdata', id));
                            if (docSnap.exists()) {
                                self.allVesselCrewIDs.push(id);
                                self.allVesselCrews.push({
                                    username: docSnap.data().username,
                                    userid: id,
                                    avatarUrl: docSnap.data().avatarUrl ?? '',
                                    role: 'levelThree'
                                });
                            }
                        }
                    })
                );
                await Promise.all(
                    levelTwo.map(async (id) => {
                        if (!self.allVesselCrewIDs.includes(id)) {
                            const docSnap = await getDoc(doc(getFirestore(), 'userdata', id));
                            if (docSnap.exists()) {
                                self.allVesselCrewIDs.push(id);
                                self.allVesselCrews.push({
                                    username: docSnap.data().username,
                                    userid: id,
                                    avatarUrl: docSnap.data().avatarUrl ?? '',
                                    role: 'levelTwo'
                                });
                            }
                        }
                    })
                );
                await Promise.all(
                    levelOne.map(async (id) => {
                        if (!self.allVesselCrewIDs.includes(id)) {
                            const docSnap = await getDoc(doc(getFirestore(), 'userdata', id));
                            if (docSnap.exists()) {
                                self.allVesselCrewIDs.push(id);
                                self.allVesselCrews.push({
                                    username: docSnap.data().username,
                                    userid: id,
                                    avatarUrl: docSnap.data().avatarUrl ?? '',
                                    role: 'levelOne'
                                });
                            }
                        }
                    })
                );
            }, 2000);
        },

        async updateCurrentVessel(updateData: { [x: string]: any }) {
            const updateRef = doc(getFirestore(), 'vessels', this.currentVesselID);
            await updateDoc(updateRef, updateData);
        },

        loadMyDashboardSettings() {
            if (!this.userID) return this.baseDashboardSettings;
            onSnapshot(doc(getFirestore(), 'userdata/' + this.userID + '/dashboardSettings', this.currentVesselID), (doc) => {
                this.myDashboardSettings = (doc.data() as DashboardSettings) ?? this.baseDashboardSettings;
            });
        },

        setColorOn(val: boolean) {
            this.colorOn = val;
        },

        setCurrentPageData(page: PageData) {
            this.currentPageData = page;
        },

        setCurrentVesselTab(tab: string) {
            this.currentVesselTab = tab;
        },

        setCurrentRequiredPlans(plan: Plan) {
            this.currentRequiredPlans = plan;
        },

        setMenuExpanded(val: boolean) {
            this.menuExpanded = val;
        },

        setSnapshotSections(val: string[]) {
            this.snapshotSections = val;
        },

        setSnapshotLayoutsChanged(val: boolean) {
            this.snapshotSectionsChanged = val;
        },

        loadFilterTags() {
            let self = this;
            const q = query(collection(getFirestore(), 'tagLibrary'), orderBy('index', 'asc'));
            onSnapshot(q, (querySnapshot) => {
                this.filterTags = [];
                querySnapshot.forEach((doc) => {
                    // const data = doc.data() as CompanyVessels;  /// TODO: Follow up with Thomas
                    const data = doc.data();
                    data.id = doc.id;
                    data.editing = false;
                    data.changed = false;
                    data.parentCatID = doc.data().parentCatID ?? 'xSu8bBuH6RbVsPdE7SWs'; // this cat ID is for "Other" cat
                    this.filterTags.push(data);
                });
            });
            setTimeout(() => {
                this.loadFilterCategories();
                this.loadMicroHelperTags();
            }, 800);
        },

        async loadMicroHelperTags() {
            const querySnapshot = await getDocs(collection(getFirestore(), 'microHelperTags'));
            this.microHelperTags = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                data.id = doc.id;
                this.microHelperTags.push(data);
            });
        },

        loadFilterCategories() {
            const q = query(collection(getFirestore(), 'tagCategories'), orderBy('index', 'asc'));
            onSnapshot(q, (querySnapshot) => {
                this.filterCategories = [];
                querySnapshot.forEach((doc) => {
                    // const data = doc.data() as CompanyVessels;  /// TODO: Follow up with Thomas
                    const data = doc.data();
                    data.id = doc.id;
                    data.editing = false;
                    data.changed = false;
                    data.tags = this.filterTags.filter((n) => n.parentCatID == doc.id);
                    this.filterCategories.push(data);
                });
            });
        }
    }
});
